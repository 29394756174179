<template>
  <mds-layout-grid class="product-section" gutters="none">
    <mds-row align-horizontal="center" class="product-section-details">
      <mds-col align-vertical="center" class="product-section-vertical-tabs" :cols="4">
        <mds-list-group>
          <mds-list-group-item
            v-for="item in products"
            :key="`list-item-${item.title}`"
            :text="item.title"
            :active="activeTab == item.id"
            @mds-list-item-clicked="setActiveItem(item)"
          >
            <span class="product-section-vertical-tab-title">{{ item.title }}</span>
            <mds-icon name="triangle-fill-right" class="product-section-vertical-tab-arrow" />
          </mds-list-group-item>
          <!-- <mds-list-group-item class="link-group-item-view-all-link" text="View All Products >">
            <router-link class="view-all-table-link" :class="{ 'disabled-router-link ': true }" :to="productsLink.productList">View All Products ></router-link>
          </mds-list-group-item> -->
        </mds-list-group>
      </mds-col>
      <mds-col align-vertical="center" :cols="8" >
        <ProductItem
          :title="activeItem.title"
          :description="activeItem.description"
          :imageFileName="activeItem.imageFileName"
          :linkSignIn="activeItem.signInLink"
          :linkKnowMore="activeItem.knowMoreLink"
        />
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsIcon from '@mds/icon';
import ProductItem from '@/components/Product/ProductItem';
import productData from '@/data/constants.json';

export default {
  name: 'Products',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsIcon,
    MdsListGroup,
    MdsListGroupItem,
    ProductItem,
  },
  data() {
    return {
      products: productData?.products,
      productsLink: productData?.links,
      activeTab: '',
      activeItem: {
        title: '',
        description: '',
        imageFilename: '',
        signInLink: '',
        knowMoreLink: '',
      },
    };
  },
  created() {
    this.setActiveItem(this.products[0]);
  },
  methods: {
    ...mapActions({
      saveActiveSignInLink: 'signIn/setActiveSignInLink',
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
    setActiveItem: function (item) {
      this.activeTab = item?.id;
      this.activeItem = item;
      this.saveActiveSignInLink(this.activeItem?.signInLink);
      this.saveSelectedProducts([this.activeItem?.id]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product-section {
  border-radius: $mds-border-radius-panel;
  border: $mds-border-separator-on-light;
  min-height: 568px;
  background: linear-gradient(153.34deg, rgba(255, 255, 255, 0.2) 9.71%, rgba(215, 215, 215, 0.49) 82.81%);
  &-details {
    height: 100%;
    padding-top: $mds-space-3-x;
  }
}

.mds-list-group {
  padding: $mds-space-2-x 0px;
}

.product-section-vertical-tab-title {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: $mds-breakpoint-s) {
    font-size: 18px;
  }
}
.mds-list-group .mds-list-group__item {
  box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.16);
  background-color: $mds-color-neutral-90;
  height: 110px;
  text-align: center;
  margin: $mds-space-1-x 0px;
  &--active {
    background-color: $mds-color-neutral-37;
    ::v-deep {
      .mds-list-group__link:visited {
        .product-section-vertical-tab-title {
          color: $mds-color-white !important;
        }
      }
      .mds-list-group__link {
        .product-section-vertical-tab-title {
          color: $mds-color-white !important;
        }
      }
      .product-section-vertical-tab-arrow {
        float: right;
        position: relative;
        margin-right: -35px;
        width: 45px;
        height: 60px;
      }
      @media (min-width: $mds-breakpoint-s) {
        .product-section-vertical-tab-arrow {
          display: flex !important;
        }
      }
    }
  }
  ::v-deep {
    .mds-list-group__link {
      height: 100%;
      .mds-list-group__item-text {
        @include mds-level-4-heading($bold: true);
        color: $mds-text-color-secondary;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      .product-section-vertical-tab-arrow {
        display: none;
      }
    }
  }
}

.link-group-item-view-all-link.mds-list-group__item{
  ::v-deep {
    .mds-list-group__link {
      cursor: default;
    }
  }
}

.view-all-table-link {
  @include mds-link($in-table: true);
  @include mds-level-6-heading($bold: true);
}

.product-section-vertical-tabs {
  border-right: 5px solid $mds-color-neutral-37;
}

.disabled-router-link {
  opacity: 0.5;
  pointer-events: none;
}
</style>
