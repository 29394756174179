import Http from "./api";

const formatResearchList = (data) => {
  return data?.researchPapers?.filter((item) => item.status === "active");
};

export const getResearchList = async () => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/datalist`;

  return Http.get(url).then((response) => {
    return formatResearchList(response?.data || []);
  });
};

export const getResearchFileUrl = async (fileName) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/file/${fileName}`;

  return Http.get(url).then((response) => {
    return response;
  });
};

export const submitResearchData = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/publish`;

  return Http.post(url, postData).then((response) => {
    return response;
  });
};

export const editResearchData = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/publish`;

  return Http.put(url, postData).then((response) => {
    return response;
  })
};

export const deleteResearchData = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/remove`;

  return Http.delete(url, postData).then((response) => {
    return response;
  })
};

export const publishResearchData = async (postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/publish/prod`;

  return Http.post(url, postData).then((response) => {
    return response;
  })
};


export const initiateUpload = async (fileName) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/initiateUpload/${fileName}`;

  return Http.post(url, {}).then((response) => {
    return response.data;
  });
};

export const uploadFileInChunk = async (fileName, postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/uploadChunk/${fileName}`;

  return Http.post(url, postData).then((response) => {
    return response.data;
  });
};

export const completeUpload = async (fileName, postData) => {
  const url = `${process.env.VUE_APP_API_BACKEND_URL}/api/researcharticle/completeUpload/${fileName}`;

  return Http.post(url, postData).then((response) => {
    return response.data;
  });
};