<template>
  <mds-layout-grid class="newsflash-section">
    <mds-row align-horizontal="center">
      <mds-col align-vertical="center" :cols="12">
        <div v-if="loading" class="newsflash-loader">
          <mds-loader/>
        </div>
        <div v-else-if="emailList.length > 0">
          <mds-row
            v-for="item in emailList"
            class="newsflash-section-item"
            align-horizontal="center"
            :key="item.id"
          >
            <mds-col :cols="12">
              <NewsFlashItem
                class="newsflash-item"
                :itemid="item.id"
                :title="item.name"
                :publishDate="item.date"
              />
            </mds-col>
          </mds-row>
          <mds-row class="newsflash-section-view-all-link">
            <mds-col :cols="12">
              <div class="newsflash-view-all-link">
                <router-link class="view-all-table-link" :to="{ path: 'newsflash/' }" >View All Newsflashes > </router-link>
              </div>
            </mds-col>
          </mds-row>
        </div>
        <div v-else class="newsflash-empty-state">
          <mds-empty-state title="No Records Found" message=""/>
        </div>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import NewsFlashItem from '@/components/NewsFlash/NewsFlashItem.vue';
import { getNewsFlashListByCount } from '@/services/newsflashServices';
import MdsEmptyState from '@mds/empty-state';
import MdsLoader from '@mds/loader';

export default {
  name: 'NewsFlashHomeList',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    NewsFlashItem,
    MdsEmptyState,
    MdsLoader
  },
  created() {
    this.getNewsFlashList();
  },
  data() {
    return {
      emailList: [],
      noOfItemstoDisplay: 6,
      loading: false,
    };
  },
  methods: {
    getNewsFlashList() {
      this.loading = true;
      getNewsFlashListByCount(this.noOfItemstoDisplay).then(async (resp) => {
        this.emailList = resp;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.newsflash-section-item {
  height: 86px;
}
.newsflash-item {
  height: 78px;
}
.newsflash-section-view-all-link {
  height: 50px;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.newsflash-section {
  border-radius: $mds-border-radius-panel;
  border: $mds-border-separator-on-light;
  height: 568px;
  overflow: hidden;
  margin-bottom: $mds-space-4-x;
}

.newsflash-view-all-link {
  text-align: center;
  background-color: $mds-background-color-light-gray;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-all-table-link {
  @include mds-link($in-table: true);
  @include mds-body-text-m($bold: true);
  color: #006FBA !important;
}
.newsflash-loader, .newsflash-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}
</style>
