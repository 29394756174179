<template>
  <div id="app">
    <top-hat v-if="!isPublishResearchRoute" />
    <mds-page-shell
      logo-href="/"
      logo-alt="Back to home page"
      :logo="logoPath"
      :logo-responsive="logoPath"
    >
      <template v-slot:mds-page-shell-masthead-right>
        <mds-button
          v-show="showGetStartedButton"
          class="mcia-get-started-btn"
          variation="primary"
          type="button"
          :size="buttonSize"
          @click="toggle = !toggle"
          v-if="!isPublishResearchRoute"
        >
          Get Started
        </mds-button>
        <mds-button
          v-show="showSignInButton"
          variation="flat"
          id="mcia-sign-in-btn"
          icon="person"
          flat-button-icon-size="medium"
          target="_blank"
          :href="signInLink"
          @click="handleSignInClick"
          v-if="!isPublishResearchRoute"
        >
          <span class="mcia-sign-in-btn-label">Sign In</span>
        </mds-button>
        <mds-button
          variation="flat"
          icon="person"
          flat-button-icon-size="medium"
          id="mcia-logout-button"
          @click="logout"
          v-if="isPublishResearchRoute"
          >Logout
        </mds-button>
      </template>
      <template v-slot:mds-page-shell-horizontal-nav v-if="showMenu && !isPublishResearchRoute">
        <Menu />
      </template>
      <div class="main-container">
        <router-view />
      </div>
      <ContactUs v-if="!hideContactUs"/>
      <Footer />
      <mds-modal
        v-model="toggle"
        class="mcia-get-started-form-model"
        aria-label="Default Modal"
        size="medium"
        :width="'900px'"
      >
        <get-started-form
          form-id="get-started-form-model"
          @exit="getStartedFormToggled"
          @toggleNotification="getStartedThankYouToggled"
        />
      </mds-modal>
    </mds-page-shell>
    <lock-screen-loader v-if="!isValidRoute"/>
    <app-alert />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MdsPageShell from '@mds/page-shell';
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import ContactUs from '@/components/ContactUs/ContactUs.vue';
import Footer from '@/components/Footer/Footer.vue';
import Menu from '@/components/Menu/Menu.vue';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';
import logo from './assets/logo.svg';
import logoResponsive from './assets/logoResponsive.svg';
import trackEvent from '@/utils/googleAnalytics';
import { events } from '@/config/events';
import TopHat from '@/components/TopHat';
import { logoutUim } from '@/userauthentication';
import LockScreenLoader from '@/components/Common/LockScreenLoader/LockScreenLoader.vue';
import AppAlert from '@/components/Common/AppAlert/AppAlert';

export default {
  components: {
    MdsPageShell,
    MdsButton,
    MdsModal,
    ContactUs,
    Footer,
    Menu,
    GetStartedForm,
    TopHat,
    LockScreenLoader,
    AppAlert
  },
  data() {
    return {
      toggle: false,
      showNotification: false,
      logoPath: logo,
      responsiveLogoPath: logoResponsive,
      buttonSize: 'medium',
    };
  },
  computed: {
    ...mapState({
      signInLink: (state) => state.signIn.activeSignInLink,
      showSignInButton: (state) => state.signIn.showSignInButton,
      showMenu: (state) => state.signIn.showMenu,
      showGetStartedButton: (state) => state.signIn.showGetStartedButton,
    }),
    hideContactUs() {
      if (this.$route.name === 'GetStartedThankYou' || this.$route.name === 'PublishResearchArticle' || this.$route.name === 'AccessDenied') {
        return true;
      }
      return false;
    },
    isPublishResearchRoute() {
      return this.$route.name === 'PublishResearchArticle' || this.$route.name === 'AccessDenied';
    },
    isValidRoute() {
      return this.$route?.matched?.some(({ name }) => name !== '');
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    getStartedFormToggled() {
      this.toggle = !this.toggle;
    },
    handleSignInClick() {
      trackEvent(events.event.SIGN_IN, events.category.SIGN_IN, events.action.CLICK, events.label.SIGN_IN, this.signInLink );
    },
    getStartedThankYouToggled(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 5000);
      }
    },
    handleWindowResize() {
      this.$refs?.myTabs?.responsiveTabs();
      if (window.outerWidth < 600) {
        this.buttonSize = 'small';
      } else {
        this.buttonSize = 'medium';
      }
    },
    logout() {
      logoutUim();
    },
  },
};
</script>

<style lang="scss">
.mds-page-shell__masthead-logo-wide,
.mds-page-shell__masthead-logo {
  min-width: 110px;
  width: auto;
  height: 30px;
}
.mds-page-shell__masthead-link {
  position: relative;
  font-size: 30px;
  color: $mds-color-red-50;
  &:hover {
    color: $mds-color-red-50;
  }
}
body {
  margin: 0;
  padding: 0;
}
.mds-page-shell__horizontal-nav {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.mds-page-shell__header {
  position: sticky;
  top: 0;
  z-index: $mds-z-index-sticky;
  box-shadow: $mds-box-shadow-drop;
}
.mds-page-shell__masthead,
.mds-page-shell__horizontal-nav,
.mcia-inner-container {
  width: 100%;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-right: $mds-space-1-x;
  padding-left: $mds-space-1-x;
}
.mcia-outer-container {
  width: 100%;
  position: relative;
}
.main-container {
  min-height: 80vh;
}
.mcia-get-started-form-model.mds-modal .mds-modal__content {
  padding-left: $mds-space-6-x;
  padding-right: $mds-space-6-x;
}
.mcia-get-started-btn {
  margin-right: $mds-space-1-x!important;
}
.mcia-sign-in-btn-label {
  @media (max-width: $mds-breakpoint-s) {
    display: none;
  }
}
.mds-page-shell__masthead-logo-wrap {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
</style>
