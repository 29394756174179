<template>
  <mds-layout-grid class="product-item-section">
    <mds-row align-horizontal="center" class="product-item-section-row">
      <mds-col
        class="product-img"
        :cols="12"
        :cols-at-s="11"
      >
        <img :src="imagePath()" />
      </mds-col>
    </mds-row>
    <mds-row align-horizontal="center" class="product-item-section-row-title">
      <mds-col
        class="product-title"
        :cols="12"
        :cols-at-s="11"
      >
        {{ title }}
      </mds-col>
    </mds-row>
    <mds-row align-horizontal="center" class="product-item-section-row-desc">
      <mds-col
        class="product-description"
        :cols="12"
        :cols-at-s="11"
      >
        <div v-html="description"/>
      </mds-col>
    </mds-row>
    <mds-row align-horizontal="center">
      <mds-col
        class="product-link-section"
        :cols="12"
        :cols-at-s="11"
      >
        <mds-button
          class="button-link"
          variation="secondary"
          target="_blank"
          :size="buttonSize"
          :href="linkSignIn"
          @click="handleSignInClick"
        >
          Sign In
        </mds-button>
        <router-link class="link-style" :to="linkKnowMore">
          <mds-button
            class="button-link"
            variation="primary"
            :size="buttonSize"
          >
            Learn More
          </mds-button>
        </router-link>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import trackEvent from '@/utils/googleAnalytics';
import { events } from '@/config/events';

export default {
  name: 'ProductItem',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    linkSignIn: {
      type: String,
      default: '',
      required: true,
    },
    linkKnowMore: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      buttonSize: 'medium',
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  methods: {
    imagePath() {
      return require(`@/assets/${this.imageFileName}`);
    },
    handleSignInClick() {
      trackEvent(events.event.SIGN_IN, events.category.SIGN_IN, events.action.CLICK, events.label.SIGN_IN, this.linkSignIn );
    },
    handleWindowResize() {
      this.$refs?.myTabs?.responsiveTabs();
      if (window.outerWidth < 600) {
        this.buttonSize = 'small';
      } else {
        this.buttonSize = 'medium';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@mds/constants';
@import '@mds/fonts';
@import '@mds/typography';
@import '@mds/link';

.product-item-section {
  padding: $mds-space-2-x;
}
.product-title {
  @include mds-level-4-heading($bold: true);
  color: $mds-text-color-secondary;
  text-align: left;
  padding: $mds-space-1-x;
}

.product-description {
  @include mds-body-text-l();
  color: $mds-text-color-secondary;
  padding: $mds-space-1-x;
}

.product-img {
  align-items: center;
  display: flex;
  justify-content: center;
}
img {
  display: block;
  padding: $mds-space-1-x;
  max-height: 250px;
  max-width: 100%;
}

.link-style {
  @include mds-link($underline: false);
  @include mds-level-1-heading();
}
.button-link {
  margin: 0 $mds-space-half-x;
}
.product-link-section {
  text-align: end;
  padding: $mds-space-1-x;
}

.product-item-section-row {
  height: 250px;
}
.product-item-section-row-title {
  height: $mds-size-element-height-l;
}
.product-item-section-row-desc {
  width: fit-content;
}
</style>
